export default {
    btnis: 'Individual Slides',
    btnepp: 'Edit in PowerPoint',
    btndpdf: 'Download a PDF',
    btnltta: 'Listen to the Audio',
    btncls: 'Close',
    btnmmr: 'Go to monthly market reports',
    stron: 'No records found with',
    strtw: 'language',
    strthr: 'Previous Editions',
    strfr: 'Click on a slide to view a larger version of it To download an individual slide click the Download button to the right of the selected slide',
}
