<template>
    <user-dashboard-layout :loading="loading">
        <v-container>
            <v-select
                v-model="selectedLang"
                :items="languages"
                item-text="label"
                item-value="code"
                label="Language">
                <template slot="selection" slot-scope="data">
                    {{ $t('languages.' + data.item.code) }}
                </template>
                <template slot="item" slot-scope="data">
                    {{ $t('languages.' + data.item.code) }}
                </template>
            </v-select>
            <template v-if="marketReport.id">
                <p v-text="marketReport.description" class="mb-5"/>
                <v-row>
                    <v-col md="8" sm="12" >
                        <video v-if="marketReport.video" id="player" playsinline controls>
                            <source :src="marketReport.video" type="video/mp4" />
                        </video>
                    </v-col>
                    <v-col md="4" sm="12">
                        <v-btn
                            v-if="marketReport.individual_slides"
                            @click="showLink(marketReport.id)"
                            block
                            color="primary"
                            depressed
                            elevation="2"
                            x-large
                            class="mb-3"
                            >{{$t('market.' + market.btnis)}}</v-btn>

                        <v-btn
                            v-if="marketReport.powerpoint"
                            :href="marketReport.powerpoint"
                            @click="incrementDownload('powerpoint')"
                            block
                            color="primary"
                            depressed
                            elevation="2"
                            x-large
                            class="mb-3"
                            >{{$t('market.' + market.btnepp)}}</v-btn>

                        <v-btn
                            v-if="marketReport.pdf"
                            :href="marketReport.pdf"
                            @click="incrementDownload('pdf')"
                            block
                            color="primary"
                            depressed
                            elevation="2"
                            x-large
                            class="mb-3"
                            >{{$t('market.' + market.btndpdf)}}</v-btn>

                        <v-btn
                            v-if="marketReport.audio"
                            :href="marketReport.audio"
                            @click.prevent="incrementDownload('audio')"
                            block
                            color="primary"
                            depressed
                            elevation="2"
                            x-large
                            class="mb-3"
                            >{{$t('market.' + market.btnltta)}}</v-btn>
                    </v-col>
                </v-row>
                <h2>{{$t('market.' + market.strthr)}}</h2>

                <v-row>
                    <v-col v-for="(report, index) in marketReports" md="4" xl="4" :key="index">
                        <h3>{{ marketReport.created_at | month }}</h3>

                        <v-card max-width="344"  class="mx-auto">
                            <v-img :src="report.individual_slides[0]" height="200px"/>

                            <v-btn
                                v-if="report.individual_slides"
                                @click="showLink(report.id)"
                                block
                                color="primary"
                                depressed
                                x-small
                                class="mb-3"
                                >{{$t('market.' + market.btnis)}}</v-btn>

                            <v-btn
                                v-if="report.powerpoint"
                                @click="handleDownload(report.powerpoint, 'powerpoint')"
                                block
                                color="primary"
                                depressed
                                x-small
                                class="mb-3"
                                >{{$t('market.' + market.btnepp)}}</v-btn>

                            <v-btn
                                v-if="report.pdf"
                                @click="handleDownload(report.pdf, 'pdf')"
                                block
                                color="primary"
                                depressed
                                x-small
                                class="mb-3"
                                >{{$t('market.' + market.btndpdf)}}</v-btn>

                            <v-btn
                                v-if="report.audio"
                                @click.prevent="handleDownload(report.audio, 'audio')"
                                block
                                color="primary"
                                depressed
                                x-small
                                class="mb-3"
                                >{{$t('market.' + market.btnltta)}}</v-btn>
                        </v-card>
                    </v-col>
                </v-row>
            </template>
            <template v-else>
                <v-alert
                    dense
                    outlined
                    type="error">
                    {{$t('market.' + market.stron)}} {{ selectedLang }} {{$t('market.' + market.strtw)}}
                </v-alert>
            </template>
        </v-container>
    </user-dashboard-layout>
</template>

<script>

import MarketReport from "@/models/MarketReport";
import UserDashboardLayout from "@/layouts/UserDashboardLayout";
import languages from "@/configs/languages";
import * as dayjs from "dayjs";
import AxiosHelper from "@/helpers/AxiosHelper";
import market from "@/configs/market";

export default {
    name: 'Show',
    components: {UserDashboardLayout},
    data() {
        return {
            marketReports: {},
            marketReport: {
                id: null
            },
            languages,
            selectedLang: 'ru',
            loading: true,
            market
        }
    },
    async mounted() {
        await this.getMarketReports();
    },
    methods: {
        async getMarketReports() {
            this.marketReports = await MarketReport
                .where('language', this.selectedLang)
                .orderBy('created_at')
                .get();

            this.marketReport = this.marketReports[0] ?? {};
            this.loading = false;
        },
        showLink(id) {
            return this.$router.push({name: 'dashboard.market-reports.show', params: {id}, query: {lang: this.selectedLang}})
        },
        async incrementDownload(entity) {
            await window.axios.get(`/api/market-reports/${this.marketReport.id}/increment-downloads/${entity}`)
        },
        handleDownload(url, entity) {
            this.incrementDownload(entity)
            AxiosHelper.download(url)
        }
    },
    filters: {
        month(date) {
            return dayjs(date).format('MMMM, YYYY');
        }
    },
    watch: {
        async selectedLang() {
            this.loading = true;
            await this.getMarketReports();
            this.loading = false;
        }
    }
}
</script>

<style scoped>
#player {
    width: 100%;
}
</style>
